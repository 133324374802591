.raw-loading-banner {
  position: relative;
  height: 100%;
  background-color: #ffffff;
}

.raw-loading-banner .app-logo {
  position: absolute;
  height: 64px;
  width: 64px;
  top: 50%;
  left: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(calc(-50% + var(--offset-x, 0px)), calc(-50% + var(--offset-y, 0px)));
}

.raw-loading-banner .pulsation {
  position: absolute;
  width: 49px;
  height: 49px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.raw-loading-banner .pulsation:before {
  content: '';
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  opacity: 0.3;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 50%;
  background-color: var(--rt-vibrant-blue);
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
