.rt-language-dropdown {
  margin: 0 0.4em;
}

.rt-language-dropdown .rt-language-dropdown-toggle-label {
  padding-top: 0.1em;
}

.rt-language-dropdown .rt-language-dropdown-item.selected {
  font-weight: 500;
}
