.rt-borderless-button.btn {
  border: none;
  --rt-borderless-button-color: var(--bs-primary);
}

.rt-borderless-button.btn,
.rt-borderless-button.btn.dropdown-toggle.show,
.rt-borderless-button.btn.dropdown-toggle.show:focus {
  background-color: transparent;
}

.rt-borderless-button.btn.color-on-hover:not(:disabled):not(:hover):not(:focus) {
  color: var(--bs-secondary);
  opacity: 0.5;
}

.rt-borderless-button.btn.color-on-hover:disabled {
  visibility: hidden;
}

.rt-borderless-button.btn:hover,
.rt-borderless-button.btn:focus,
.rt-borderless-button.btn:active:focus,
.rt-borderless-button.btn.active:focus,
.rt-borderless-button.btn.dropdown-toggle.show,
.rt-borderless-button.btn.dropdown-toggle.show:focus {
  color: var(--rt-borderless-button-color);
  box-shadow: none;
}

.rt-borderless-button.btn:hover,
.rt-borderless-button.btn.dropdown-toggle.show:hover {
  background-color: var(--rt-background-hover);
}

.rt-borderless-button.btn:focus,
.rt-borderless-button.btn:active:focus,
.rt-borderless-button.btn.active:focus,
.rt-borderless-button.btn.dropdown-toggle.show:focus {
  background-color: var(--rt-background-focus);
}

.rt-borderless-button.btn.color-on-hover:hover,
.rt-borderless-button.btn.color-on-hover:focus {
  background-color: transparent;
}
