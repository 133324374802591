.authentication-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.authentication-layout .authentication-header {
  display: flex;
  justify-content: flex-end;
  margin: 0.5em 1em;
  padding-bottom: 0.3em;
  border-bottom: solid #e9e9e9 1px;
}

.authentication-layout .authentication-footer {
  margin: 2em auto;
}

.authentication-layout .authentication-footer .brand-image {
  height: 3em;
}

.authentication-layout .authentication-body {
  display: flex;
  flex-direction: column;
  font-size: 1.15rem;
  flex-grow: 1;
}

.authentication-layout .authentication-body .authentication-logo {
  margin: 1em auto 3em auto;
  display: flex;
  justify-content: center;
}

.authentication-layout .authentication-body .authentication-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 30em;
  margin: 0 auto 2em auto;
}

.authentication-layout .authentication-body .app-image {
  height: 6em;
}

.authentication-layout .authentication-body input,
.authentication-layout .authentication-body button {
  font-size: 1.15rem;
}

.authentication-layout .authentication-body .error-message-global .alert {
  display: none;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
  width: auto;
  font-size: 100%;
}

.authentication-layout .authentication-body .error-message-global .alert {
  display: inline-block;
}

.authentication-layout .authentication-body .link {
  text-decoration: none;
}

.authentication-layout .authentication-body .link:hover {
  text-decoration: underline;
}

.authentication-layout .authentication-body .link-container {
  margin: 2em 0 1em;
  text-align: center;
}

.authentication-layout .authentication-body .recaptcha-submit {
  width: 100%;
}
