@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

html,
body {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  /* font-feature-settings: 'lnum'; TODO Use lining numbers for numbers not displayed in paragraphs */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

/* forces feedbacks to be visible since bootstrap is not initialized vanilla-fashion at the moment */
body .invalid-feedback {
  display: unset;
}

/* Bootstrap style to fix v5.2 */

.form-control[disabled] {
  cursor: default;
  pointer-events: none;
  background-color: #e9ecef;
  opacity: 1;
}

/** General style to isolate **/

input:not([type='textarea']) {
  font-feature-settings: 'lnum' 1;
}

body .form-group {
  margin-bottom: 1rem;
}

.btn-primary {
  background-color: var(--rt-vibrant-blue);
  border-color: var(--rt-vibrant-blue);
}

.btn-primary:hover {
  background-color: var(--rt-vibrant-blue-hover);
  border-color: var(--rt-vibrant-blue-hover);
}
