.rt-button-wrapper {
  display: inline;
}

.btn.rt-button {
  display: flex;
  align-items: center;
  /*padding: 0.5rem 0.8rem;*/
}

.btn.rt-button.rt-justify-content-center {
  justify-content: center;
}
.btn.rt-button.rt-justify-content-start {
  justify-content: start;
}
.btn.rt-button.rt-justify-content-end {
  justify-content: end;
}
.btn.rt-button.rt-justify-content-flex-start {
  justify-content: flex-start;
}
.btn.rt-button.rt-justify-content-flex-end {
  justify-content: flex-end;
}
.btn.rt-button.rt-justify-content-left {
  justify-content: left;
}
.btn.rt-button.rt-justify-content-right {
  justify-content: right;
}
.btn.rt-button.rt-justify-content-normal {
  justify-content: normal;
}
.btn.rt-button.rt-justify-content-space-between {
  justify-content: space-between;
}
.btn.rt-button.rt-justify-content-space-around {
  justify-content: space-around;
}
.btn.rt-button.rt-justify-content-space-evenly {
  justify-content: space-evenly;
}
.btn.rt-button.rt-justify-content-stretch {
  justify-content: stretch;
}

.btn.rt-button.rt-rounded {
  border-radius: 1000000px;
}

.btn.rt-button.rt-circular {
  border-radius: 50%;
  padding: var(--bs-btn-padding-x) var(--bs-btn-padding-x);
}

.btn.rt-button.full-size {
  height: 100%;
  width: 100%;
}

.btn.rt-button.rt-outline:disabled {
  border-color: var(--bs-secondary); /* #6c757d */
  color: var(--bs-secondary); /* #545b62 */
}

.rt-button .spinner {
  /* ensures spinner takes the same place as material icons (24px) */
  width: 16px;
  height: 16px;
  margin: 4px;
}

.rt-button .spinner.sm {
  /* ensures spinner takes the same place as small material icons (20px) */
  width: 12px;
  height: 12px;
  margin: 4px;
}

.rt-button .button-text {
  padding-left: 0.6rem;
}
