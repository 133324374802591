.material-symbols-outlined {
  cursor: inherit;
  user-select: none;
}

.inline-symbol {
  --inline-icon-size: 0;
  position: relative;
}

.inline-symbol::before {
  content: 'xx';
  visibility: hidden;
  padding-right: var(--inline-icon-size);
}

.inline-symbol:first-child,
.inline-symbol + .inline-symbol {
  margin-left: -1ex;
}

.inline-symbol:last-child {
  margin-right: -1ex;
}

.inline-symbol .material-symbols-outlined {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
