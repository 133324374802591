.rt-dropdown-menu {
  --bs-dropdown-padding-x: 0px; /* Setting this value to '0px' instead of '0' to prevent calc errors */
  --rt-dropdown-menu-border-color: transparent;
  --rt-dropdown-menu-shadow-color-rgb: 0, 0, 0;
  --rt-dropdown-menu-shadow-color-opacity: 0;
  --rt-dropdown-menu-border-inset: ;
  cursor: default;
  border: none;
  box-shadow: var(--rt-dropdown-menu-border-inset) 0 0 0 1px var(--rt-dropdown-menu-border-color), 0 3px 8px 0 rgba(var(--rt-dropdown-menu-shadow-color-rgb), var(--rt-dropdown-menu-shadow-color-opacity));
}

.rt-dropdown-menu:focus-visible {
  outline: none;
}

.rt-dropdown-menu.rt-shadow {
  --rt-dropdown-menu-shadow-color-opacity: 0.2;
}

.rt-dropdown-menu.rt-inner-border,
.rt-dropdown-menu.rt-outer-border {
  --rt-dropdown-menu-border-color: var(--bs-dropdown-border-color);
}

.rt-dropdown-menu.rt-inner-border {
  --rt-dropdown-menu-border-inset: inset;
  padding: calc(1px + var(--bs-dropdown-padding-y)) calc(1px + var(--bs-dropdown-padding-x));
}

.rt-dropdown-menu > .rt-dropdown-menu-arrow,
.rt-dropdown-menu > .rt-dropdown-menu-arrow::after {
  --rt-dropdown-menu-arrow-size: 5px;
  --rt-dropdown-menu-arrow-size-diagonal: 7px; /* This value is the precomputed length of the arrow square diagonal */
  --rt-dropdown-menu-arrow-color: var(--bs-dropdown-bg);
  --rt-dropdown-menu-arrow-shadow-color: rgba(var(--rt-dropdown-menu-shadow-color-rgb), calc(var(--rt-dropdown-menu-shadow-color-opacity) / 8));
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
}

.rt-dropdown-menu > .rt-dropdown-menu-arrow::after {
  content: '';
  border: solid var(--rt-dropdown-menu-arrow-size) transparent;
}

.rt-dropdown-menu[data-popper-placement^='top'] > .rt-dropdown-menu-arrow {
  bottom: 0;
  left: calc(var(--rt-dropdown-menu-arrow-size) * -1);
}

.rt-dropdown-menu[data-popper-placement^='bottom'] > .rt-dropdown-menu-arrow {
  top: 0;
  left: calc(var(--rt-dropdown-menu-arrow-size) * -1);
}

.rt-dropdown-menu[data-popper-placement^='left'] > .rt-dropdown-menu-arrow {
  top: calc(var(--rt-dropdown-menu-arrow-size) * -1);
  right: 0;
}

.rt-dropdown-menu[data-popper-placement^='right'] > .rt-dropdown-menu-arrow {
  top: calc(var(--rt-dropdown-menu-arrow-size) * -1);
  left: 0;
}

.rt-dropdown-menu[data-popper-placement^='top'] > .rt-dropdown-menu-arrow::after {
  bottom: calc(var(--rt-dropdown-menu-arrow-size) * -2);
  left: calc(var(--rt-dropdown-menu-arrow-size) - var(--rt-dropdown-menu-arrow-size-diagonal));
  border-color: transparent transparent var(--rt-dropdown-menu-arrow-color) var(--rt-dropdown-menu-arrow-color);
  box-shadow: -1px 1px 0 0 var(--rt-dropdown-menu-border-color), -2px 2px 2px 0 var(--rt-dropdown-menu-arrow-shadow-color);
  transform-origin: top left;
  transform: rotate(-45deg);
}

.rt-dropdown-menu[data-popper-placement^='bottom'] > .rt-dropdown-menu-arrow::after {
  top: calc(var(--rt-dropdown-menu-arrow-size) * -2);
  left: calc(var(--rt-dropdown-menu-arrow-size) - var(--rt-dropdown-menu-arrow-size-diagonal));
  border-color: var(--rt-dropdown-menu-arrow-color) transparent transparent var(--rt-dropdown-menu-arrow-color);
  box-shadow: -1px -1px 0 0 var(--rt-dropdown-menu-border-color), -2px -2px 2px 0 var(--rt-dropdown-menu-arrow-shadow-color);
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.rt-dropdown-menu[data-popper-placement^='left'] > .rt-dropdown-menu-arrow::after {
  top: calc(var(--rt-dropdown-menu-arrow-size) - var(--rt-dropdown-menu-arrow-size-diagonal));
  right: calc(var(--rt-dropdown-menu-arrow-size) * -2);
  border-color: var(--rt-dropdown-menu-arrow-color) var(--rt-dropdown-menu-arrow-color) transparent transparent;
  box-shadow: 1px -1px 0 0 var(--rt-dropdown-menu-border-color), 2px -2px 2px 0 var(--rt-dropdown-menu-arrow-shadow-color);
  transform-origin: top left;
  transform: rotate(45deg);
}

.rt-dropdown-menu[data-popper-placement^='right'] > .rt-dropdown-menu-arrow::after {
  top: calc(var(--rt-dropdown-menu-arrow-size) - var(--rt-dropdown-menu-arrow-size-diagonal));
  left: calc(var(--rt-dropdown-menu-arrow-size) * -2);
  border-color: var(--rt-dropdown-menu-arrow-color) transparent transparent var(--rt-dropdown-menu-arrow-color);
  box-shadow: -1px -1px 0 0 var(--rt-dropdown-menu-border-color), -2px -2px 2px 0 var(--rt-dropdown-menu-arrow-shadow-color);
  transform-origin: top right;
  transform: rotate(-45deg);
}

.rt-dropdown-menu.rt-scrollable {
  height: auto;
  max-height: 50vh;
  overflow-x: hidden;
}

.rt-dropdown-menu.rt-scrollable.rt-menu-sm {
  max-height: 25vh;
}

.rt-dropdown-menu.rt-scrollable.rt-menu-lg {
  max-height: 75vh;
}

.rt-dropdown-menu.rt-scrollable.rt-menu-xl {
  max-height: 100vh;
}
