:root {
  /* Bootstrap variables */
  --bs-darker: #1e2125;
  --bs-darker-rgb: 30, 33, 37;
  /* Roseau Technologies variables */
  --rt-blue: #234e83;
  --rt-vibrant-blue: #222798;
  --rt-vibrant-green: #cad40e;
  --rt-blue-hover: #3e7ecc;
  --rt-vibrant-blue-hover: #484fd5;
  --rt-background-hover: rgba(0, 0, 0, 0.04);
  --rt-background-focus: rgba(0, 0, 0, 0.1);
  --rt-background-inactive: rgba(0, 0, 0, 0.15);
  --rt-opacity-secondary: 0.7;
  --rt-opacity-disabled: 0.5;
  /* */
  --rt-green-light: #2F8011;
  --rt-orange-light: #E6AD00;
  --rt-red-light: #FF5656;
  --rt-green-light-rgb: 47, 128, 17;
  --rt-orange-light-rgb: 215, 140, 0;
  --rt-red-light-rgb: 255, 86, 86;
}

.rt-info {
  color: #2f6bb4;
}

.rt-success {
  color: #7a8009;
}

.rt-warning {
  color: #f79e05;
}

.rt-error {
  color: #dc3545;
}
