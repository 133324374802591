.rt-dropdown-item {
  --rt-dropdown-item-hover-bg: var(--bs-dropdown-link-hover-bg);
  --rt-dropdown-item-focus-bg: #e0e5e8;
  --rt-dropdown-item-icon-padding: calc(20px + 0.5rem);
  position: relative;
}

.rt-dropdown-item:not(.active):hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--rt-dropdown-item-hover-bg);
}

.rt-dropdown-item:not(.active):focus,
.rt-dropdown-item:not(.active):focus-within,
.rt-dropdown-item:not(.active):active {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--rt-dropdown-item-focus-bg);
}

.rt-dropdown-item:focus-visible {
  outline: none;
}

.rt-dropdown-item.has-icon {
  padding-left: calc(var(--bs-dropdown-item-padding-x) + var(--rt-dropdown-item-icon-padding));
}

.rt-dropdown-item .rt-dropdown-item-icon {
  position: absolute;
  left: calc(var(--bs-dropdown-item-padding-x) - 2px);
  top: 50%;
  transform: translateY(-50%);
}
