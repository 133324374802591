.rt-dropdown-item-check .rt-dropdown-item-check-form {
  padding-left: var(--rt-dropdown-item-icon-padding);
  margin-bottom: 0;
}

.rt-dropdown-item-check .rt-dropdown-item-check-form input {
  margin-left: calc(1px - var(--rt-dropdown-item-icon-padding));
}

.rt-dropdown-item-check .rt-dropdown-item-check-form label {
  pointer-events: none;
}
